import { Suspense, useState, useEffect, lazy, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Heading, Button, Flex } from 'theme-ui';
import { logout, verifyToken } from './redux/feature/authSlice';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './styles/customToast.scss';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Router from './Router';
import FreeRouter from './FreeRouter';

import { parseQueryParams } from './constant/constant';
import { PUBLIC_URL, backendBaseUrl } from './api/config';
import ZoomLogo from './asset/icons/try-11-min.png';
import PlayStore from './asset/icons/playStore.svg';
import ApplePlay from './asset/icons/applePlay.svg';
import MobileBG from './asset/images/newMobileBG.svg';
import { getwindowSizes } from './utils/utilityFunction';
import useWindowSize from './utils/useWindowSize';
import { cleverTapEventPush } from './utils/cleverTap';
import { CLEVER_TAP_EVENTS } from './config/cleverTapEvents';

const MaintenancePage = lazy(() =>
  import('./components/MaintenancePage/MaintenancePage')
);

function App() {
  const [enableMaintenanceMode, setEnableMaintenanceMode] = useState(0);

  const dispatch = useDispatch();
  const location = useLocation();

  const checkMaintenanceMode = async () => {
    try {
      const url = `${backendBaseUrl}/login/checkmaintenance.php`;
      const response = await fetch(url);
      const data = await response.json();
      // console.log("DATA", parseInt(data.enabled));
      let enableStatus = parseInt(data.enabled);
      setEnableMaintenanceMode(enableStatus);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // checkMaintenanceMode();
    const param = parseQueryParams(location.search);
    const token = localStorage.getItem('token');
    const freeToken = localStorage.getItem('freeToken');
    if (param?.logout === 'true') {
      dispatch(logout());
      const newURL = window.location.pathname;
      window.history.replaceState({}, '', newURL);
      return;
    }
    if (token != null && !location.pathname.includes('/free')) {
      const userData = JSON.parse(localStorage.getItem('user'));
      dispatch(verifyToken({ token: token, userData: userData }));
    }
    if (
      freeToken == null &&
      param?.token == null &&
      location.pathname.includes('/free')
    ) {
      window.location.replace('https://herovired.com/');
    }
    if (
      (freeToken != null || param?.token != null) &&
      location.pathname.includes('/free')
    ) {
      let freeTk = param?.token || freeToken;
      dispatch(verifyToken({ token: freeTk, withoutUserData: true }));
    }
  }, []);
  function ErrorFallback({ error, resetErrorBoundary }) {
    const location = useLocation();
    const err = { text: `${error} at  ${location.pathname}` };
    return (
      <Flex
        variant='appContent'
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '110px',
          backgroundColor: 'accent.light'
        }}
      >
        <Heading as='h5'>Something went wrong</Heading>
        {/* <Image src={ErrorImage} alt='Error'></Image>
        <Heading as='h5'>{error.message}</Heading> */}
        <Button sx={{ marginTop: '25px' }} as={Link} to='/'>
          Return to Dashboard
        </Button>
      </Flex>
    );
  }

  const accessType = useSelector((state) => state?.auth?.userData?.accessType);
  const { width } = useWindowSize();
  const handleAppClick = (store) => {
    cleverTapEventPush(CLEVER_TAP_EVENTS.App_Benefits_Action, {
      Item_Selected: store
    });
  };
  return (
    <>
      {/* <AuthIsLoaded> */}

      <Suspense fallback={ErrorFallback}>
        {
        width <= getwindowSizes.s_tempdesktop &&
        width >= getwindowSizes.s_tablet &&
        // false &&
        !location.pathname.includes('/free') &&
        width > 650 ? (
          <div
            style={{
              background:
                'linear-gradient(249deg, #C2D5FF 26%, #D7FFF8 73.92%)',
              height: '100vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                width: '60%',
                background: `url(${PUBLIC_URL}/asset/img/dashboard/Ellipse.svg)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                height: '100vh'
              }}
              className='left-short-img'
            >
              <div className='left-short-img-container'>
                <img
                  src='https://assets.herovired.com/wp-content/uploads/2022/06/hero-vired-cobrand-logo.svg'
                  alt='testing'
                />
                <div>
                  <h6>Unlock Your Learning Potential Download the App Now!</h6>
                  <p>
                    The Hero Vired app brings you a seamless and engaging
                    learning experience, right at your fingertips. Access
                    courses, join live sessions, and track your progress.
                  </p>
                </div>
                <div
                  className='w-100 d-flex flex-column'
                  style={{ gap: '8px' }}
                >
                  <div className='app-store-button'>
                    <img
                      src={PlayStore}
                      alt='play store'
                      onClick={() => {
                        handleAppClick('Play_Store');
                        if (
                          navigator.userAgent.toLowerCase().indexOf('android') >
                          -1
                        ) {
                          window.location = 'market://details?id=com.herov.lms';
                        } else {
                          window.location =
                            'itms-apps://apps.apple.com/us/app/hero-vired/id1561006649';
                        }
                      }}
                    />
                    <img
                      src={ApplePlay}
                      alt='appleplay'
                      onClick={() => {
                        handleAppClick('App_Store');
                        if (
                          navigator.userAgent.toLowerCase().indexOf('android') >
                          -1
                        ) {
                          window.location = 'market://details?id=com.herov.lms';
                        } else {
                          window.location =
                            'itms-apps://apps.apple.com/us/app/hero-vired/id1561006649';
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                flexGrow: 1,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <img
                src={MobileBG}
                alt='MobileBG'
                style={{
                  height: '73%',
                  width: '100%'
                }}
              />
            </div>
          </div>
        ) : width < 650 && !location.pathname.includes('/free') ? (
          <div className='zoom-mobile-view'>
            <div className='zoom-mobile-view-img'>
              <img
                src='https://assets.herovired.com/wp-content/uploads/2022/06/hero-vired-cobrand-logo.svg'
                alt='HeroViredlogo'
                style={{ 
                  width: '200px',
                  height: '60px'
                }}
              />
            </div>
            <div className='zoom-mobile-img'>
              <img src={ZoomLogo} className='zoom-trying-img' alt='zoom-logo' />
              <div className='zoom-mobile-bottom-container'>
                <div>
                  <p className='zoom-mobile-heading'>
                    Unlock Your Learning Potential Download the App Now!
                  </p>
                  <p className='zoom-mobile-subHeading'>
                    The Hero Vired app brings you a seamless and engaging
                    learning experience, right at your fingertips. Access
                    courses, join live sessions, and track your progress.
                  </p>
                </div>
                <div
                  className='w-100 d-flex flex-column'
                  style={{ gap: '8px' }}
                >
                  <div className='app-store-button'>
                    <img
                      src={PlayStore}
                      alt='play store'
                      onClick={() => {
                        handleAppClick('Play_Store');
                        if (
                          navigator.userAgent.toLowerCase().indexOf('android') >
                          -1
                        ) {
                          window.location = 'market://details?id=com.herov.lms';
                        } else {
                          window.location =
                            'itms-apps://apps.apple.com/us/app/hero-vired/id1561006649';
                        }
                      }}
                    />
                    <img
                      src={ApplePlay}
                      alt='appleplay'
                      onClick={() => {
                        handleAppClick('App_Store');
                        if (
                          navigator.userAgent.toLowerCase().indexOf('android') >
                          -1
                        ) {
                          window.location = 'market://details?id=com.herov.lms';
                        } else {
                          window.location =
                            'itms-apps://apps.apple.com/us/app/hero-vired/id1561006649';
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {enableMaintenanceMode == 1 ? (
                <MaintenancePage />
              ) : accessType === 'Free' &&
                location.pathname.includes('/free') ? (
                FreeRouter()
              ) : (
                Router()
              )}
            </ErrorBoundary>
            <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          </Fragment>
        )}
      </Suspense>
      {/* </AuthIsLoaded> */}
    </>
  );
}
export default App;
